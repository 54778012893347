import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AwardsHeroSectionComponent from '../components/awards-page/AwardsHeroSectionComponent';
import AchievementsComponent from '../components/awards-page/AchievementsComponent';
import AwardsSectionComponent from '../components/awards-page/AwardsSectionComponent';

// Affiliates Images
import dcciiImage from '../../static/images/awards-affiliates/dccii.png';
import pcciImage from '../../static/images/awards-affiliates/pcci.png';
import ccciImage from '../../static/images/awards-affiliates/ccci-min.png';
import philgepsImage from '../../static/images/awards-affiliates/philgeps-min.png';
import corenetImage from '../../static/images/awards-affiliates/core-net-min.jpg';

// Awards Images
import asiaAwardImage1 from '../../static/images/awards-affiliates/awards/AL-award-1-min.jpg';
import asiaAwardImage2 from '../../static/images/awards-affiliates/awards/AL-award-2-min.jpg';
import asiaAwardImage3 from '../../static/images/awards-affiliates/awards/AL-award-3-min.jpg';
import davaoAwardImage from '../../static/images/awards-affiliates/awards/davao-award-min.jpg';
import goldenAwardImage from '../../static/images/awards-affiliates/awards/golden-award-min.jpg';
import globalAwardImage from '../../static/images/awards-affiliates/awards/global-award-min.jpg';
import LinesAndNodesComponent from '../components/LinesAndNodesComponent';
import AffiliatesSectionComponent from '../components/awards-page/AffiliatesSectionComponent';

export const affiliatesImages = [
  {
    path: pcciImage,
    alt: 'PCCI',
    style: { md: 6, sm: 10, xs: 10, maxHeight: '120px' },
  },

  {
    path: philgepsImage,
    alt: 'Philgeps',
    style: { md: 6, sm: 10, xs: 10, maxHeight: '120px' },
  },
  {
    path: corenetImage,
    alt: 'Core Net',
    style: { md: 6, sm: 10, xs: 10, maxHeight: '140px' },
  },
  {
    path: dcciiImage,
    alt: 'DCCII',
    style: { md: 4, sm: 8, xs: 8, maxHeight: '140px' },
  },
  {
    path: ccciImage,
    alt: 'CCCI',
    style: { md: 4, sm: 8, xs: 8, maxHeight: '140px' },
  },
];

const awards = [
  {
    id: 6,
    image: asiaAwardImage1,
    title: 'Real Estate Company of the Year',
    event: 'Asia Leaders Awards 2019',
    date: 'November 19, 2019',
  },
  {
    id: 5,
    image: asiaAwardImage2,
    title: 'Young CEO of the Year - Jet Yu',
    event: 'Asia Leaders Awards 2019',
    date: 'November 19, 2019',
  },
  {
    id: 4,
    image: asiaAwardImage3,
    title: 'Young Businessman of the Year - Jet Yu',
    event: 'Asia Leaders Awards 2019',
    date: 'November 19, 2019',
  },
  {
    id: 3,
    image: davaoAwardImage,
    title: 'Business Development and Entrepreneurship Award',
    event: 'Pasidungog: Garbo sa Davao Awards',
    date: 'March 12, 2018',
  },
  {
    id: 2,
    image: goldenAwardImage,
    title: 'Best Real Estate Consultancy Firm',
    event: '2017 Golden Globe Awards for Business Excellence',
    date: 'June 14, 2017',
  },
  {
    id: 1,
    image: globalAwardImage,
    title: 'Best Real Estate Service Company',
    event: '29th Global Excellence Awards',
    date: 'May 12, 2017',
  },
];

function AwardsPage({ location }) {
  return (
    <Layout source="Awards and Recognitions Page">
      <SEO
        title="Awards and Recognition"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <Row type="flex" justify="center">
        <Col xxl={16} sm={24} xs={24}>
          <div className="line-node-wrapper">
            <LinesAndNodesComponent
              position="left"
              style={{ zIndex: 1, backgroundSize: 'cover', width: 250 }}
            />
            <AwardsHeroSectionComponent />
            <LinesAndNodesComponent
              set={1}
              position="right"
              style={{ zIndex: 1, backgroundSize: 'cover', width: 350 }}
            />
          </div>

          <div className="line-node-wrapper">
            <LinesAndNodesComponent set={2} position="left" />
            <AchievementsComponent />

            <LinesAndNodesComponent
              set={1}
              position="right"
              style={{ backgroundSize: 'cover', width: 350 }}
            />
          </div>

          <AffiliatesSectionComponent />

          <div className="line-node-wrapper">
            <LinesAndNodesComponent set={2} position="left" />
            <AwardsSectionComponent awards={awards} />
            <LinesAndNodesComponent set={1} position="right" />
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

AwardsPage.propTypes = {
  location: PropTypes.object,
};

export default AwardsPage;
