/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const StyledDiv = styled.div`
  padding: 48px 0px;

  h2 {
    text-align: center;
    /* margin-bottom: 32px; */
  }

  .subtitle {
    text-align: center;
    margin-bottom: 32px;
  }

  .styled-card {
    height: 100%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

    .details {
      background: white;
      padding: 8px 16px;

      h4 {
        color: ${props => props.theme.primeBluePalette.original};
        margin-bottom: 4px;
        line-height: 24px;
      }

      .event,
      .date {
        font-size: 14px;
        line-height: 18px;
        color: ${props => props.theme.blackPalette.light};
      }
    }
  }
`;

const StyledCoverImage = styled.div`
  background-image: ${props => `linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.18),
      rgba(0, 0, 0, 0.24)
    ),url(${props.coverImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
`;

function AwardsSectionComponent({ awards }) {
  return (
    <StyledDiv>
      <Row type="flex" justify="center" className="styled-row">
        <Col xxl={16} xl={17} lg={18} md={21} sm={21} xs={21}>
          <h2>Awards and Recognitions</h2>
          <p className="subtitle">
            {`In recognition of the company's aim for excellence, numerous
            organizations and institutions have awarded PRIME Philippines and
            its representatives for various accomplishments and milestones.`}
          </p>
          <Row type="flex" gutter={[16, 16]}>
            {awards.map(value => (
              <Col lg={8} sm={12} xs={24} key={value.id}>
                <div className="styled-card">
                  <StyledCoverImage coverImage={value.image} />
                  <div className="details">
                    <h4>{value.title}</h4>
                    <div className="event">{value.event}</div>
                    <div className="date">{value.date}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </StyledDiv>
  );
}

export default AwardsSectionComponent;
