import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Slider from 'react-slick';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const partners = [
  {
    name: 'ULI',
    description:
      'The oldest and largest organization of real estate and land development experts in the world globally',
    logo: '../../images/awards-affiliates/partners/uli.png',
  },
  {
    name: 'ECCP',
    description:
      'A bilateral foreign chamber that promotes business opportunities between Europe and the Philippines',
    logo: '../../images/awards-affiliates/partners/eccp.png',
  },
  {
    name: 'Makati Tourism Foundation Inc.',
    description:
      'An organization that promotes Makati City as a premiere destination for business and tourism',
    logo: '../../images/awards-affiliates/partners/makati-tourism.png',
  },
  {
    name: 'PCCI',
    description:
      'The largest chamber in the Philippines that promotes businesses from various industries',
    logo: '../../images/awards-affiliates/partners/pcci.png',
  },
  {
    name: 'DCCCII',
    description:
      'Davao City’s  leading organization that supports growth in the business community',
    logo: '../../images/awards-affiliates/partners/dcccii.png',
  },
  {
    name: 'CoreNet Global',
    description: 'A global network of corporate real estate professionals',
    logo: '../../images/awards-affiliates/partners/corenet.png',
  },
  {
    name: 'CREBA',
    description:
      'The umbrella organization of the real estate and housing industry in the Philippines',
    logo: '../../images/awards-affiliates/partners/creba.png',
  },
  {
    name: 'REBAP',
    description:
      'An institution of licensed real estate professionals dedicated to professionalizing the real estate industry and setting its ethical standards',
    logo: '../../images/awards-affiliates/partners/rebap.png',
  },
  {
    name: 'PAREB',
    description:
      'The Philippines’ first and largest non-profit organization of real estate specialists nationwide',
    logo: '../../images/awards-affiliates/partners/pareb.png',
  },
];

const StyledDivWrapper = styled.div`
  background-image: url('../../images/awards-affiliates/bg-affiliation.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  padding: 48px 0px;

  h1,
  p {
    color: white;
  }

  h1 {
    text-align: center;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom: 6px;
  }

  img {
    max-width: 200px;
    margin: 0px auto 6px;
  }

  .partner-wrapper {
    text-align: center;
  }

  .slick-slide {
    padding: 8px 16px;
  }

  .prev-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .next-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    right: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .partner-name {
    color: #fad127;
  }
`;

function NextArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="next-slider"
      style={{ ...style, display: 'block', color: '#FFFFFF' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-right" fixedWidth />
    </div>
  );
}

function PrevArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="prev-slider"
      style={{ ...style, display: 'block', color: '#FFFFFF' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-left" fixedWidth />
    </div>
  );
}

function AffiliatesSectionComponent() {
  const settings = {
    initialSlide: 0,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledDivWrapper>
      <Row type="flex" align="middle" justify="center">
        <Col xs={18} sm={18} md={20} lg={20} xl={20} xxl={18}>
          <div>
            <h1>Our Partner Organizations</h1>
            <div>
              <Slider {...settings}>
                {partners.map(partner => (
                  <div key={partner.name} className="partner-wrapper">
                    <img src={partner.logo} alt={partner.name} />
                    <h3 className="partner-name">{partner.name}</h3>
                    <p>{partner.description}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default AffiliatesSectionComponent;
