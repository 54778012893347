/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import Scroll from 'react-scroll';

// const { scroller } = Scroll;
// const { Element } = Scroll;

const contentDiv = [
  {
    title: `The Prime Partner for Developers`,
    content: `A proven partner for commercial real estate developers, PRIME works hand-in-hand with property owners and their development teams to ensure full occupancy for projects handled. Due to their dynamic approach to project marketing, PRIME is the premier option for the bold developer. PRIME’s approach to project marketing involves innovation and strong utilization of technology.`,
    extraContent: [
      {
        src: `../../images/awards-affiliates/icons/icon1.png`,
        text: `Square meters of real estate handled`,
      },
      {
        src: `../../images/awards-affiliates/icons/icon2.png`,
        text: `Have worked with top companies, including Ayala Corporation, SM Investments Corporation, Megaworld Corporation, Jollibee Foods Corporation, and GT Capital Holdings, among others`,
      },
    ],
  },
  {
    title: `The Expansion Companion`,
    content: `Companies looking to aggressively grow their presence in the country look to PRIME Philippines for expedited results that cover the different major cities in Luzon, Visayas, and Mindanao. PRIME has a proven track record of bringing in new concepts to established markets, as well as in helping growing brands tap new, underserved markets nationwide.`,
    extraContent: [
      {
        src: `../../images/awards-affiliates/icons/companies.png`,
        text: `Prominent companies and brands represented`,
      },
      {
        src: `../../images/awards-affiliates/icons/cities.png`,
        text: `Cities covered`,
      },
    ],
  },
  {
    title: `The Problem Solver`,
    content: `Given the innovative and fast-paced nature of PRIME Philippines, the company looks to partner with developers to strengthen their underperforming projects. PRIME takes pride in its track record of reinvigorating slow-moving projects through assigning a dedicated team to implement creative marketing and looking beyond the typical target markets for each development. Among its portfolio are projects previously handled by multinational real estate consultancy firms and properties that have been long in the market.`,
    extraContent: [
      {
        src: `../../images/awards-affiliates/icons/occupancy.png`,
        text: `Average occupancy after 6-12 months`,
      },
      {
        src: `../../images/awards-affiliates/icons/projects.png`,
        text: `Projects reinvigorated`,
      },
    ],
  },
  {
    title: `Building Lasting Partnerships`,
    content: `PRIME’s growth can be attributed to the trust given by its various clients, with an over 95% satisfaction rating and through continuous previous client referrals, PRIME organic rise to being a reliable real estate partner is largely due to its belief that each client should be treated as a partner. The goal of PRIME with each client is to bring them to new heights and be successful in real estate.`,
    extraContent: [
      {
        src: `../../images/awards-affiliates/icons/clients.png`,
        text: `Clients served`,
      },
      {
        src: `../../images/awards-affiliates/icons/wordofmouth.png`,
        text: `Of clients from word-of-mouth referral`,
      },
    ],
  },
];

const StyledDivWrapper = styled.div`
  text-align: center;
  h3 {
    margin-bottom: 30px;
  }

  .content-title {
    background: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.whitePalette.original};
    padding: 6px 12px;
    box-shadow: -5px 5px 0px 0px ${props => props.theme.primeBluePalette.dark};
    text-transform: uppercase;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .add-diamond-line:after {
    border-color: ${props => props.theme.primeBluePalette.original};
  }

  .content-div {
    border-bottom: 2px solid ${props => props.theme.primeBluePalette.original};
    margin-bottom: 60px;
    :last-child {
      border-bottom: 0px solid white;
    }
  }

  p {
    font-size: 1.1em;
  }

  .img-slider {
    margin-bottom: 0px;
    box-shadow: -5px 5px 0px 0px rgba(0, 31, 61, 1);
  }

  .slick-slide {
    padding: 16px;
  }

  .prev-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .next-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    right: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .read-more {
    color: ${props => props.theme.primeBluePalette.original};
    border: 1px solid ${props => props.theme.primeBluePalette.original};
    font-weight: bold;
    padding: 6px 10px;

    :hover {
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.primeYellowPalette.original};
      cursor: pointer;
    }
  }

  .ReactCollapse--collapse {
    min-height: 63px;
    transition: height 500ms;
    transition-delay: 200ms;
  }

  .ReactCollapse--content {
    position: relative;
    > .collapsed-div:before {
      content: '';
      width: 100%;
      height: 63px;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 43%,
        rgba(255, 255, 255, 0.84) 100%
      );
    }
  }

  .achievement-title {
    font-size: 3rem;
    color: ${props => props.theme.primeBluePalette.original};
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
    margin: 3rem 0px;

    @media only screen and (max-width: 400px) {
      font-size: 2.3rem;
    }
  }
  img {
    margin-bottom: 0px;
  }
  .img-stat {
    max-width: 200px;
    margin: auto;
    padding: 20px;
  }

  svg:hover {
    cursor: pointer;
  }
`;

function AchievementsComponent() {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <StyledDivWrapper>
      <Row type="flex" align="middle" justify="center">
        <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={18}>
          <h1 className="achievement-title">What we achieved so far</h1>

          {contentDiv.map((content, i) => (
            <div
              //   name={`search-result-${i}`}
              key={content.title}
              className={`content-div ${
                i !== contentDiv.length - 1 ? 'add-diamond-line' : ''
              }`}
            >
              <div style={{ marginBottom: 20 }}>
                <h3 className="content-title">{content.title}</h3>
                <Collapse
                  isOpened={i === activeIndex}
                  initialStyle={
                    i === activeIndex
                      ? { height: 'auto', overflow: 'initial' }
                      : { height: '63px', overflow: 'hidden' }
                  }
                >
                  <div className={i !== activeIndex ? 'collapsed-div' : ''}>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{ __html: content.content }}
                      />
                    </div>
                    <div>
                      <Row type="flex" align="middle" justify="center">
                        <Col xs={23} sm={23} md={22} lg={18} xl={18} xxl={18}>
                          <Row>
                            {content.extraContent.map(extra => (
                              <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                key={extra.text}
                              >
                                <img
                                  className="img-stat"
                                  src={extra.src}
                                  alt={extra.text}
                                />
                                <p>{extra.text}</p>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Collapse>
              </div>
              {activeIndex !== i ? (
                <div>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size="2x"
                    fixedWidth
                    onClick={() => {
                      setActiveIndex(i);
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default AchievementsComponent;
