import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import coverImage from '../../../static/images/awards-affiliates/awards-recog-cover-image.jpg';

const StyledDiv = styled.div`
  padding: 89px 0px 0px 0px;
  position: relative;

  background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.6)
    ),
    url(${coverImage});
  background-size: cover;
  background-position: center 90%;
  background-repeat: no-repeat;
  height: 500px;

  .styled-row {
    height: 100%;
    color: ${props => props.theme.whitePalette.original};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.32);
  }

  h1 {
    font-weight: normal;
    b {
      font-weight: bolder;
      color: ${props => props.theme.primeYellowPalette.original};
    }
  }
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 1.35em;
    line-height: 1.3;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: #00162ba1;
  }

  .content-paragraph {
    z-index: 1;
    position: relative;
  }
`;

function AwardsHeroSectionComponent() {
  return (
    <StyledDiv coverImage={coverImage}>
      <Row type="flex" justify="center" className="styled-row">
        <Col md={18} sm={21} xs={21}>
          <Row
            type="flex"
            align="middle"
            justify="center"
            className="styled-row content-paragraph"
          >
            <Col xl={18} lg={16} sm={24} xs={24}>
              <h1>
                YOUR <b>SUCCESS</b> IS OUR <b>BUSINESS</b>
              </h1>
              <p>
                In PRIME Philippines, we take pride in our commitment in helping
                our clients achieve success in their real estate businesses. Our
                company will not be where we are today without their trust and
                support.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledDiv>
  );
}

export default AwardsHeroSectionComponent;
